import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SiteLogo from "./Logo"
import MobileNav from "./MobileNav"
import { graphql, StaticQuery } from "gatsby"

import styled from "styled-components"

const NavBar = styled.nav`
  width: 100%;
  height: 4.75rem;
  display: flex;
  justify-content: space-between;

  .logo {
    display: flex;
    justify-content: flex-start;
  }

  ul {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    li {
      padding: 1.125rem;
      border-right: 1.3px solid #000;

      a {
        color: #000;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 960px) {
    ul {
      display: none;
    }
  }
`

const MainMenu = () => (
  <>
    <NavBar>
      <div className="logo">
        <SiteLogo />
      </div>

      <StaticQuery
        query={graphql`
          {
            allWordpressWpApiMenusMenusItems(
              filter: { name: { eq: "Main Menu" } }
            ) {
              edges {
                node {
                  items {
                    title
                    object_slug
                    object_id
                    url
                  }
                }
              }
            }
          }
        `}
        render={props => (
          <ul>
            {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
              item => {
                return (
                  <li key={item.object_id}>
                    <AniLink
                      // paintDrip
                      // hex="#e0a94a"
                      // duration={1}
                      to={
                        item.object_slug === "go-back-to-main-site-2"
                          ? "https://triton-series.com/"
                          : "/" + item.object_slug
                      }
                    >
                      {item.title}
                    </AniLink>
                  </li>
                )
              }
            )}
          </ul>
        )}
      />

      <MobileNav />
    </NavBar>
  </>
)

export default MainMenu
