import React from "react";
import Head from "../Head";
import Header from "../Header";
import Footer from "../Footer";

import "./reset.css";
import styled from "styled-components";

const LayoutWapper = styled.main`
	flex: 1 0 auto;
	margin: 0 auto;
	overflow: hidden;
	/* padding-top: 1rem; */
	/* padding-left: 1rem;
  padding-right: 1rem; */
	animation: fadein 2.5s ease-out;

	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const PageLayout = ({ children }) => {
	return (
		<>
			<Head />
			<Header />
			<LayoutWapper>{children}</LayoutWapper>
			<Footer />
		</>
	);
};

export default PageLayout;
