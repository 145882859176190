import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { graphql, StaticQuery } from "gatsby"

import styled from "styled-components"

const LogoInner = styled.div`
  clip-path: polygon(69% 0, 100% 100%, 0 100%, 0 0);
  background-color: #000;
  width: 100%;
  height: inherit;
  padding: 1.1rem;
  .logo_Image {
    max-width: 145px;
  }

  @media (max-width: 960px) {
    padding: 1.1rem;
  }
`

const SiteLogo = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpLogo {
          edges {
            node {
              url {
                alt_text
                source_url
              }
            }
          }
        }
      }
    `}
    render={props => (
      <AniLink
        to="/home"
        // paintDrip
        // duration={1.5}
        // hex="#e0a94a"
        aria-label="Home"
      >
        <LogoInner>
          <div className="logo_Image">
            <img
              src={props.allWordpressWpLogo.edges[0].node.url.source_url}
              alt={props.allWordpressWpLogo.edges[0].node.url.alt_text}
            />
          </div>
        </LogoInner>
      </AniLink>
    )}
  />
)

export default SiteLogo
