import React from "react"
import MainMenu from "./Nav/MainMenu"

import styled from "styled-components"

const HeaderWapper = styled.header`
  display: flex;
  height: 100%;
  background: rgb(247, 209, 134);
  background: radial-gradient(
    circle,
    rgba(247, 209, 134, 1) 0%,
    rgba(248, 211, 138, 1) 31%,
    rgba(224, 169, 74, 1) 78%
  );
`

const Header = () => {
  return (
    <>
      <HeaderWapper>
        <MainMenu />
      </HeaderWapper>
    </>
  )
}

export default Header
