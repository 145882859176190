import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { graphql, StaticQuery } from "gatsby"
import {
  FaFacebookF,
  FaInstagram,
  FaTwitch,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa"

import styled from "styled-components"

const UL = styled.div`
  display: none;
  list-style: none;
  justify-content: center;

  .mobileUl {
    padding: 1.125rem;
    text-align: center;
    a {
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }

  .mobileSocial {
    padding: 0.5rem;
    margin-top: 1.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    h4 {
      color: #fff;
      width: 100%;
      text-align: center;
      padding: 0.7rem;
      margin-bottom: 0.5rem;
    }
    a {
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 960px) {
    display: flex;
    flex-flow: column nowrap;
    background-color: rgb(0, 0, 0, 1);
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s ease-in-out;
    z-index: 1006;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }
`
const RightNav = ({ open }) => (
  <>
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(
            filter: { name: { eq: "Main Menu" } }
          ) {
            edges {
              node {
                items {
                  title
                  object_slug
                  object_id
                }
              }
            }
          }
        }
      `}
      render={props => (
        <UL open={open}>
          {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
            item => {
              return (
                <React.Fragment key={item.object_id}>
                  <div className="mobileUl">
                    <AniLink
                      // paintDrip
                      // hex="#e0a94a"
                      // duration={1.5}
                      to={
                        item.object_slug === "go-back-to-main-site-2"
                          ? "https://triton-series.com/"
                          : "/" + item.object_slug
                      }
                    >
                      {item.title}
                    </AniLink>
                  </div>
                </React.Fragment>
              )
            }
          )}
          <div className="mobileSocial">
            <h4>Let's Get Social</h4>
            <a href="https://www.facebook.com/TritonPoker/">
              <FaFacebookF />
            </a>
            <a href="https://twitter.com/tritonpoker">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/tritonpokerseries/">
              <FaInstagram />
            </a>
            <a href="https://www.twitch.tv/tritonpoker">
              <FaTwitch />
            </a>
            <a href="https://www.youtube.com/tritonpoker">
              <FaYoutube />
            </a>
          </div>
        </UL>
      )}
    />
  </>
)

export default RightNav
