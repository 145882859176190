import React, { useState } from "react"
import RightNav from "./RightNav"

import styled from "styled-components"

const HamMenu = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.9375rem;
  right: 1.25rem;
  z-index: 1007;
  display: none;
  cursor: pointer;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#fff" : "#f7d186")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) =>
        open ? "translateX(-100%)" : "translateX(0)"};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media (max-width: 960px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    background-color: #000;
    width: 12%;
    position: fixed;
    right: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 0.5rem;
    align-items: center;
    box-shadow: 0px 1px 15px -5px rgba(0, 0, 0, 0.75);
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 17%;
  }
`

const MobileNav = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <HamMenu open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </HamMenu>
      <RightNav open={open} />
    </>
  )
}

export default MobileNav
