import React from "react"
import Layout from "../components/Layout/Layout"

import styled from "styled-components"

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`

const Page = ({ pageContext }) => {
  return (
    <Layout>
      <PageContent>
        <h1>{pageContext.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
      </PageContent>
    </Layout>
  )
}

export default Page
